// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

// tailwindCSS
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// toastUI editor
@import "~@toast-ui/editor/dist/toastui-editor.css";

html {
    scroll-behavior: smooth;
}

.text-stroke {
    -webkit-text-stroke: 1px black;
}

.min-h-screen-1\/3 {
    min-height: calc(100vh / 3);
}

.min-h-screen-2\/3 {
    min-height: calc(100vh * 2 / 3);
}

.min-h-screen-1\/4 {
    min-height: calc(100vh / 4);
}

.min-h-screen-3\/4 {
    min-height: calc(100vh * 3 / 4);
}

@screen md {
    .min-h-screen-1\/3 {
        min-height: calc(100vh / 3);
    }

    .min-h-screen-2\/3 {
        min-height: calc(100vh * 2 / 3);
    }

    .min-h-screen-1\/4 {
        min-height: calc(100vh / 4);
    }

    .min-h-screen-3\/4 {
        min-height: calc(100vh * 3 / 4);
    }
}


main.main-wrapper {
    padding-top: 40px;
}

@screen md {
    main.main-wrapper {
        padding-top: 58px;
    }
}

.markdown-viewer .tui-editor-contents {
    img {
        @apply md:w-2/3 w-full mx-auto
    }

    table {
        tr:last-child > td:first-child {
            @apply rounded-bl-lg
        }

        tr:last-child > td:last-child {
            @apply rounded-br-lg
        }

        @apply md:w-2/3 w-full mx-auto divide-y divide-gray-200 shadow border-none rounded-lg
    }

    th, td {
        @apply border-none
    }


    th {
        &:first-child {
            @apply rounded-tl-lg
        }
        &:last-child {
            @apply rounded-tr-lg
        }

        @apply text-sm font-medium text-gray-500 uppercase tracking-wider bg-gray-200
    }

    @apply text-base space-y-4 tracking-wide
}

#news .tag {
    color: #B1BAC9;
    background-color: #F4F5F7;
}
